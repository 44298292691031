export default [
  { name: '周邊環境', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '' },
  { name: '依山面水', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '' },
  { name: '與山對話', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '' },
  { name: '用心深根', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '' },
  { name: '亮麗的家', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '' },
  { name: '時尚美學', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '' },
  { name: '三峽靜好', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '' },
  { name: '預約賞屋', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '' },
]
