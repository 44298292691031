export default {
  address: '新北市三峽區中山路398號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3617.8850565436187!2d121.35148511561106!3d24.935985348366223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3468190cc1934669%3A0xbd3ed138586a28ec!2zMjM35paw5YyX5biC5LiJ5bO95Y2A5Lit5bGx6LevMzk46Jmf!5e0!3m2!1szh-TW!2stw!4v1615175990499!5m2!1szh-TW!2stw',
  phone: '02-26737555',
  fbLink: 'https://www.facebook.com/%E5%8C%97%E5%A4%A7%E6%A8%82%E9%A6%A5-112627960268481',
  fbMessage: 'https://m.me/112627960268481',
  googleLink: 'https://goo.gl/maps/7QziBfEpRQkFDZFW6',
  caseName: '北大樂馥',

  houseInfos: [
    ['投資興建', '弘華機構'],
    ['投資興建', '和彩建設有限公司'],
    ['設計團隊', '意石設計有限公司'],
    ['建築設計', 'PGA周簡建築師事務所'],
    ['建照案號', '106峽建字第00212']
  ],

  gtmCode: ['N9B9BQF'], // 可放置多個
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
